import "core-js/modules/es.array.splice.js";
import { get_info, post_brandlist } from "../../api/index";
export default {
  name: "basetable",
  data: function data() {
    return {
      hoteldatares: {
        token: localStorage.getItem("token"),
        type: "brand",
        id: "",
        keyword: this.keyword,
        // 搜索
        page_index: 1,
        page_size: 5
      },
      addform: {
        type: "add",
        brand_name: this.brand_name,
        desc: this.desc,
        image_url: ""
      },
      editform: {
        type: "edit",
        brand_name: this.brand_name,
        desc: this.desc,
        image_url: "",
        id: ''
      },
      query: {
        address: "",
        name: ""
      },
      rules: {
        brand_name: [{
          required: true,
          message: "请输入品名称",
          trigger: "blur"
        }],
        desc: [{
          required: true,
          message: "请输入品牌描述",
          trigger: "blur"
        }] //    role: [
        //      {
        //        required: true,
        //        message: "请选择角色",
        //        trigger: "blur",
        //      }
        //    ],

      },
      tableData: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      permissionBeans: []
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData: function getData() {
      var _this = this;

      get_info(this.hoteldatares).then(function (res) {
        _this.tableData = res.data.list;
        _this.pageTotal = res.data.count;
      });
    },
    //创建时间格式化
    timestampToTime: function timestampToTime(row) {
      if (row.create_time == 0) {
        return "-";
      } else {
        var date = new Date(row.create_time * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000

        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() + 1 < 10 ? '0' + (date.getDate() + 1) : date.getDate() + 1) + ' ';
        var h = (date.getHours() + 1 < 10 ? '0' + (date.getHours() + 1) : date.getHours() + 1) + ':';
        var m = (date.getMinutes() + 1 < 10 ? '0' + (date.getMinutes() + 1) : date.getMinutes() + 1) + ':';
        var s = (date.getSeconds() + 1 < 10 ? '0' + (date.getSeconds() + 1) : date.getSeconds() + 1) + ' ';
        return Y + M + D + h + m + s;
      }
    },
    // 删除操作
    handleDelete: function handleDelete(index, row) {
      var _this2 = this;

      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning"
      }).then(function () {
        console.log(row);
        var data = {
          id: row.id,
          type: "delete"
        };
        post_brandlist(data).then(function (res) {
          if (res.code == 1) {
            console.log(res);

            _this2.$message.success("删除成功");

            _this2.tableData.splice(index, 1);

            _this2.getData();
          } else {
            _this2.$message.error(res.msg);
          }
        });
      }).catch(function () {});
    },
    addmenu: function addmenu() {
      this.addVisible = true;
    },
    // 添加保存
    addmenuFun: function addmenuFun(addform) {
      var _this3 = this;

      console.log(this.addform);
      this.$refs[addform].validate(function (valid) {
        if (valid) {
          post_brandlist(_this3.addform).then(function (res) {
            if (res.code == 1) {
              _this3.addVisible = false;

              _this3.$message.success(res.msg);

              _this3.getData();
            } else {
              console.log(res);

              _this3.$message.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑操作
    handleEdit: function handleEdit(index, row) {
      this.idx = index;
      this.editform.brand_name = row.brand_name;
      this.editform.desc = row.desc;
      this.editform.id = row.id;
      this.editVisible = true;
    },
    addsmall: function addsmall() {
      this.$message.success("\u6DFB\u52A0\u5B50\u83DC\u5355");
    },
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      console.log(file); // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
    },
    // 保存编辑
    saveEdit: function saveEdit() {
      var _this4 = this;

      post_brandlist(this.editform).then(function (res) {
        if (res.code == 1) {
          _this4.editVisible = false;

          _this4.$message.success(res.msg);

          _this4.getData();
        } else {
          _this4.$message.error(res.msg);
        }
      });
    },
    // 分页导航
    handlePageChange: function handlePageChange(val) {
      this.$set(this.query, "pageIndex", val);
      this.getData();
    }
  }
};